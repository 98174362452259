import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import { withPrefix } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
// import { graphql, useStaticQuery } from "gatsby";

const Wrapper = styled.div`
  .image-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 2rem 0;

    .image {
      display: flex;
      img {
        margin: auto;
        max-width: 100%;
      }
    }
  }

  .no-image {
    background: #d8d8d8;
    height: 400px;
    width: 100%;
    border: 2px solid #979797;
  }

  .caption {
    color: ${({ theme }) => theme.secondary};
    padding: 8px 0;
  }
`;

// const getImageNodeFromData = (data, key) => {
//   const image = data.allFile.edges.filter(({ node }) => node.name === key)[0];
//   if (image) {
//     return image.node;
//   }
// };
//
// const imageFileQuery = graphql`
//   query {
//     allFile(filter: { sourceInstanceName: { eq: "images" } }) {
//       edges {
//         node {
//           name
//           childImageSharp {
//             gatsbyImageData(placeholder: BLURRED)
//           }
//         }
//       }
//     }
//   }
// `;

// simplifying to use the /static folder so gifs and svgs work
export const Image = (props) => {
  const { src, alt = "", caption = "" } = props;
  // const src = props.src.split(".")[0];
  // const data = useStaticQuery(imageFileQuery);
  // const imageName = src.split("/").slice(-1).pop();
  // const image = getImage(getImageNodeFromData(data, imageName));
  return (
    <Wrapper className="block">
      <Row>
        <Col span={24}>
          <div className="image-container">
            <div className="image" key={`image/${src}`}>
              <img src={withPrefix(`/${props.src}`)} alt={alt} />
              {/*<GatsbyImage image={image} alt={alt} {...rest} />*/}
            </div>
            <div
              className="caption"
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};
