import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  color: ${({ theme }) => theme.secondary};
  padding-top: 1rem;
  
  #share-buttons {
    display: inline-block; vertical-align: middle;
    }
  #share-buttons:after {
    content: ""; 
    display: block; 
    clear: both;
  }
  
  #share-buttons > div {
    position: relative;
    text-align: left;
    height: 36px;
    width: 32px;
    float: left;
    text-align: center;
  }
  
  #share-buttons > div > svg {height: 16px; fill: ${({ theme }) =>
    theme.secondary}; margin-top: 10px}
  #share-buttons > div:hover {cursor: pointer;}
  #share-buttons > div.facebook:hover > svg {fill: #3B5998;}
  #share-buttons > div.twitter:hover > svg {fill: #55ACEE;}
  #share-buttons > div.linkedin:hover > svg {fill: #0077b5;}
  #share-buttons > div.pinterest:hover > svg {fill: #CB2027;}
  #share-buttons > div.gplus:hover > svg {fill: #dd4b39;}
  #share-buttons > div.mail:hover > svg {fill: #7D7D7D;}
  #share-buttons > div.instagram:hover > svg {fill: #C73B92;}
  #share-buttons > div.facebook > svg {height: 18px; margin-top: 9px;}
  #share-buttons > div.twitter > svg {height: 20px; margin-top: 8px;}
  #share-buttons > div.linkedin > svg {height: 19px; margin-top: 7px;}
  #share-buttons > div.pinterest > svg {height: 20px; margin-top: 9px;}
  #share-buttons > div.gplus > svg {height: 17px; margin-top: 9px; position: relative left: 1px;}
  #share-buttons > div.mail > svg {height: 14px; margin-top: 11px;}
`;

export default function ShareButtons() {
  return (
    <Wrapper>
      <span id="share-buttons">Share on </span>
      <div id="share-buttons">
        <div
          className="facebook"
          title="Share this on Facebook"
          role="link"
          tabIndex="0"
          aria-hidden="true"
          onClick={() =>
            window.open(
              `http://www.facebook.com/share.php?u=${window.location.href}`
            )
          }
        >
          <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" />
          </svg>
        </div>
        <div
          className="twitter"
          title="Share this on Twitter"
          role="link"
          tabIndex="0"
          aria-hidden="true"
          onClick={() =>
            window.open(
              `http://twitter.com/home?status=${window.location.href}`
            )
          }
        >
          <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z" />
          </svg>
        </div>
        <div
          className="linkedin"
          title="Share this on Linkedin"
          role="link"
          tabIndex="0"
          aria-hidden="true"
          onClick={() =>
            window.open(
              `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=&summary=&source=`
            )
          }
        >
          <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z" />
          </svg>
        </div>
        <div
          className="gplus"
          title="Share this on Google Plus"
          role="link"
          tabIndex="0"
          aria-hidden="true"
          onClick={() =>
            window.open(
              `https://plus.google.com/share?url=${window.location.href}`
            )
          }
        >
          <svg viewBox="0 0 2304 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M1437 913q0 208-87 370.5t-248 254-369 91.5q-149 0-285-58t-234-156-156-234-58-285 58-285 156-234 234-156 285-58q286 0 491 192l-199 191q-117-113-292-113-123 0-227.5 62t-165.5 168.5-61 232.5 61 232.5 165.5 168.5 227.5 62q83 0 152.5-23t114.5-57.5 78.5-78.5 49-83 21.5-74h-416v-252h692q12 63 12 122zm867-122v210h-209v209h-210v-209h-209v-210h209v-209h210v209h209z" />
          </svg>
        </div>
        <div
          className="mail"
          title="Share this through Email"
          role="link"
          tabIndex="0"
          aria-hidden="true"
          onClick={() => window.open(`mailto:?&body=${window.location.href}`)}
        >
          <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z" />
          </svg>
        </div>
      </div>
    </Wrapper>
  );
}
