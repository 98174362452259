import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Image } from "./Image";
import styled from "styled-components";
import Layout from "./Layout";
import { Col, Row } from "antd";
import Authors from "./Authors";
import CodeHighlight from "./CodeHighlight";
import ShareButtons from "./ShareButtons";
import { columnProps, getReadableDate } from "../helpers";
import useScript from "../hooks/script";
import {Navigation} from "./Navigation";

const Wrapper = styled.div`

  .header {
    padding-bottom: 1.5rem;
  }

  .dot {
    padding: 0 4px;
  }
`;

// Provide common components here
const shortcodes = {
  Link,
  Image,
  pre: CodeHighlight,
  Row,
  Col,
};

export default function PageTemplate({ data: { mdx } }) {
  // TODO remove this hack ... gatsby-plugin-mdx does not work with mathjax so we need
  //  to force mathjax to render the latex on blog post load
  let status = useScript(
    "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"
  );
  useEffect(() => {
    if (
      (window.MathJax && status === "ready") ||
      (window.MathJax &&
        window.MathJax.startup &&
        window.MathJax.startup.defaultReady)
    ) {
      window.MathJax.startup.defaultReady();
    }
  }, [status]);

  const isAuthorPresent = !!mdx.frontmatter.author;
  return (
    <Layout>
      <div className="white-background">
        <Wrapper>
          <Row className="content-row">
            <Col {...columnProps}>
              <Navigation />
              <div className="header">
                <h1>{mdx.frontmatter.title}</h1>
                <div className="inline">
                  <div className="secondary">
                    {getReadableDate(mdx.frontmatter.date)}
                    {isAuthorPresent && <span className="dot">•</span>}
                  </div>
                  {isAuthorPresent && (
                    <Authors authors={mdx.frontmatter.author} />
                  )}
                </div>
              </div>
              <MDXProvider components={shortcodes}>
                <MDXRenderer frontmatter={mdx.frontmatter}>
                  {mdx.body}
                </MDXRenderer>
              </MDXProvider>
              <div>
                If you have errata or small fixes, <a href={"mailto:josh@generallyintelligent.ai"}>message me</a> and I will update the post thanking you and linking wherever you want.
              </div>
              <div>
                If you disagree entirely, publish a full critique, <a href={"mailto:josh@generallyintelligent.ai"}>send it to me</a>, and I will link it here.
              </div>
              <div>
                Share and discuss on twitter:
              </div>
            </Col>
          </Row>
        </Wrapper>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        layout
        date
        tags
        snippet
        title
        group
        author
      }
    }
  }
`;
