import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
`;

export default function Authors({ authors }) {
  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: authors.join(", ") }} />
    </Wrapper>
  );
}
